<script setup lang="ts">
interface IProps {
    size?: string | number
}
const props = defineProps<IProps>()
import useUserStore from '../../stores/useUserStore'
const userStore = useUserStore()
</script>
<template>
    <div class="rounded-full border-gray-500 border-2">
        <div class="m-1 rounded-full border-gray-200 border-2">
             <img alt="avatar" class="rounded-full shadow-lg" :width="`${props.size || '90'}px`" :class="`max-w-[${props.size || '50'}px]`" :src="userStore.getAvatar ? `${userStore.getAvatar}`: userStore.getAvatarUrl || ''" v-if="userStore.getAvatar || userStore.getAvatarUrl" />
            <svg v-else class="text-blue-500" :class="{'h-6 w-6': !props.size}":width="props.size" :height="props.size" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
        </div>
    </div>
</template>