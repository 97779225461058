import AnalyticsService from '../services/AnalyticsService';

const analyticsService = AnalyticsService.getInstance()
export const storeAnalytics = async (dto: any, generateNewSession: boolean) => {
    const ip = localStorage.getItem('anonymus_ip')
    analyticsService.post({ ip_addr: ip, session_id: localStorage.getItem('sessionId'), ...dto })
    if (generateNewSession) {
        generateSessionId()
    }
}

export const generateSessionId = () => {
    let sessionId = '';
    while (sessionId.length < 36) {
        sessionId += Math.random().toString(36).substr(2);
    }
    localStorage.setItem('sessionId', sessionId);
}