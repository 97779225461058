<script setup lang="ts">
import { ref } from 'vue';
import { generateSocialLink } from '../../shared/helpers/social';
import donateImg from '../../assets/donation.png'
import { event } from 'vue-gtag';

const recordEvent = (eventToRecord: string) => {
    event(eventToRecord)
}
</script>
<template>
    <div class="flex items-center justify-center gap-0 bg-pink-400 rounded-lg rounded-e-none fixed right-0 top-[50%] z-30 select-none">
        <div
            class="flex flex-col items-center jusitfy-center">
            
            <ul class="list-unstyled flex flex-col p-2 gap-3">
                <li @click="recordEvent('side_social_clicked_donate')">
                    <router-link to="donate" aria-label="Donate">
                        <img class="hover:shadow-2xl" :src="donateImg" alt="Twitter X profile" width="20px" height="20px"
                            style="display: inline-block; vertical-align: baseline;" />
                    </router-link>
                </li>
                <li @click="recordEvent('side_social_clicked_facebook')">
                    <a target="_blank" aria-label="Visit our Facebook page"
                        :href="generateSocialLink('https://www.facebook.com/AudioAIDynamicsPage', 'fb://profile/AudioAIDynamicsPage', 'intent://www.facebook.com/AudioAIDynamicsPage#Intent;package=com.facebook.katana;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/facebook-icon.svg" alt="Facebook profile" width="20px" height="20px"
                            style="display: inline-block; vertical-align: baseline; height: 20px;" />
                    </a>
                </li>
                <li @click="recordEvent('side_social_clicked_instagram')">
                    <a target="_blank" aria-label="Visit our Instagram profile"
                        :href="generateSocialLink('https://www.instagram.com/audioaidynamics', 'instagram://user?username=audioaidynamics', 'intent://www.instagram.com/audioaidynamics#Intent;package=com.instagram.android;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/instagram-icon.svg" alt="Instagram profile" width="20px" height="20px"
                            style="display: inline-block; vertical-align: baseline; height: 20px;" />
                    </a>
                </li>
                <li @click="recordEvent('side_social_clicked_X')">
                    <a target="_blank" aria-label="Visit our X profile"
                        :href="generateSocialLink('https://twitter.com/audio_dynamics_', 'twitter://user?screen_name=audio_dynamics_', 'intent://twitter.com/audio_dynamics_#Intent;package=com.twitter.android;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/twitter-x-icon.svg" alt="Twitter X profile" width="20px" height="20px"
                            style="display: inline-block; vertical-align: baseline; height: 20px;" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>