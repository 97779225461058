import baseEntityService from '../services/baseEntityService'

export default class ForgotPasswordService extends baseEntityService<any> {
  private static instance: ForgotPasswordService

  private constructor() {
    super('/api/forgot-password')
  }

  public static getInstance(): ForgotPasswordService {
    if (!ForgotPasswordService.instance)
      return new ForgotPasswordService()

    return ForgotPasswordService.instance
  }

  public confirmForgotPassword = (dto: any) => {
    return this.postRequest(`api/confirm-forgot-password`, dto)
  }
}
