
import axios, { AxiosRequestConfig } from 'axios';
import LoginService from '../services/LoginService';
import { getCookie, setCookie } from './cookieHelpers';

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;
axios.interceptors.request.use(config => {
  const accessToken = getCookie('access_token')
  if (accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`
  return config
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axios
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });
          refreshAndRetryQueue.length = 0;
          return axios(originalRequest);
        } catch (refreshError) {
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    return Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  const resp = await LoginService.getInstance().refreshToken()
  setCookie('access_token', resp.data.accessToken, (resp.data.accessTokenExpiresIn / 86400))
  setCookie('refresh_token', resp.data.refreshToken, (resp.data.refreshTokenExpiresIn / 86400))
  return resp.data.accessToken
}