<script setup lang="ts">
import { MainMenu } from '../../shared/helpers'
import useLoginStore from '../../stores/useLoginStore';
import useUserStore from '../../stores/useUserStore';
import UserMenu from '../user/UserMenu.vue';
const loginStore = useLoginStore()
const userStore = useUserStore()
const showLogin = () => {
  loginStore.setShowLoginModal(true)
}
</script>
<template>
<div class="flex flex-col gap-3 z-40 justify-between max-w-[127px] fixed left-0 bottom-0 h-full text-white bg-gray-800 pt-3">
    <ul class="relative z-50 p-3 gap-5 flex flex-col text-center">
        <li v-for="(item, ind) in MainMenu" :key="ind">
          <RouterLink :to="item.link" class="hover:text-gray-100 text-[10px] flex items-center flex-col justify-center gap-1">
          <img :src="item.icon" :alt="item.name" class="max-w-[35%]"/>
          {{ item.name }}
          </RouterLink>
        </li>
        <li class="cursor-pointer">
          <span @click="showLogin" class="hover:text-gray-100 text-[10px] flex items-center flex-col justify-center gap-1"  v-if="!userStore.getAuthenticated">
            <img src="../../assets/listening.webp" alt="Login" class="max-w-[35%]"/>
            <span>LOGIN</span>
          </span>
          <UserMenu :show-carret="false" v-else/>
        </li>
      </ul>
</div>

</template>