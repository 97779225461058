import { defineStore } from 'pinia'
import type { IUser } from '../shared/models/UserModel'
import LoginService from '../shared/services/LoginService'

interface UserStoreInterface {
  user: IUser | null
  authenticated: boolean
  language: string
}
const loginStore = LoginService.getInstance()
const useUserStore = defineStore({
  id: 'useUserStore',
  state: () =>
    ({
      user: null,
      authenticated: false,
      language: navigator.language,
    } as UserStoreInterface),
  getters: {
    getUserId: state => state.user?.id,
    getUser: state => state.user,
    getAuthenticated: state => state.authenticated,
    getLanguage: state => state.language,
    getAvatar: state => state.user?.avatarImage,
    getAvatarUrl: state => state.user?.avatarUrl,
  },
  actions: {
    setUser(user: IUser) {
      this.user = user
    },
    setAuthenticated(authenticated: boolean) {
      if (!authenticated) {
        loginStore.logout()
      }
      this.authenticated = authenticated
    },
  },
})

export default useUserStore
