<script setup lang="ts">
    const emits = defineEmits(['onClose'])
    const closePopup = () => {
        emits('onClose')
    }
</script>
<template>
    <div class="flex flex-col fixed z-30 top-0 bottom-0 max-w-[600px] min-w-[360px] rounded-lg mx-auto my-auto p-5 max-h-[850px] h-fit bg-white shadow-lg left-0 right-0">
        <div class="cursor-pointer flex self-end hover:bg-gray-400 hover:text-white font-bold w-fit border-bg-black rounded-full border-2 px-1.5" @click="closePopup">X</div>
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
    </div>
</template>