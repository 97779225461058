import { createApp, reactive, watch } from 'vue'
import './style.css'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import { router } from './router'
import en from './translations/en.json'
import es from './translations/es.json'
import VueGtag from "vue-gtag";
import { pinia } from './stores/index'
import './shared/helpers/interceptors'
import AaidGames from 'aaid-games';
import 'aaid-games/dist/style.css'

const messages = {
    en: en,
    es: es
}
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})
let analyticsCookie = false
let allCookies = localStorage.getItem('cookie-comply')
if (allCookies) {
    allCookies = JSON.parse(allCookies)
    if (allCookies?.includes('analytics')) {
        analyticsCookie = true
    }
}
const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(AaidGames);
app.use(pinia)
const state = reactive({
    analyticsCookie: false // Initial value
});

watch(
    () => state.analyticsCookie,
    (newValue) => {
        if (newValue) {
            app.use(VueGtag, {
                appName: 'Audio AI Dynamics',
                pageTrackerScreenviewEnabled: true,
                config: { id: 'G-760QLTDMM5' }
            }, router);
        } else {
            // @ts-ignore
            const gtag = window['gtag'];
            if (gtag) {
                gtag('consent', 'update', { analytics_storage: 'denied' });
            }
        }
    },
    { immediate: true }
);
app.provide('analyticsState', state);

if (analyticsCookie) {
    app.use(VueGtag, {
        bootstrap: false,
        appName: 'Audio AI Dyanmics',
        pageTrackerScreenviewEnabled: true,
        config: { id: "G-760QLTDMM5" }
    }, router)
}

app.mount('#app')