<script setup lang="ts">
import { isMobile } from '../../shared/helpers';
import { generateSocialLink } from '../../shared/helpers/social';

const menu = [/* {
    name: 'About',
    link: '/about'
}, */
    {
        name: 'Contact',
        link: '/contact'
    },
    {
        name: 'FAQ',
        link: '/faq'
    },
    {
        name: 'Privacy Policy',
        link: '/privacy-policy'
    },
    {
        name: 'Roadmap',
        link: '/roadmap'
    },
    {
        name: 'Donate',
        link: '/donate'
    },
    /* {
        name: 'Terms and Conditions',
        link: '/terms-and-conditions'
    } */
]
</script>
<template>
    <div class="flex flex-col md:flex-row justify-center max-md:gap-5 items-center p-10 pb-5 bg-white z-30">
        <div class="h-1 w-full border-b-2 border-grey-100" v-if="isMobile()" />
        <div class="flex min-w-fit">
            <span>© 2025 AudioAIDynamics</span>
        </div>
        <div
            class="flex flex-col md:flex-row md:gap-10 w-full justify-end lg:me-20 items-center bottom-0 relative basis-full">
            <RouterLink :to="item.link" v-for="(item, ind) in menu" :key="ind" class="hover:text-[#230FBF]">{{ item.name
                }}
            </RouterLink>
            <ul class="list-unstyled flex gap-5">
                <li>
                    <a target="_blank" aria-label="Visit our Facebook page"
                        :href="generateSocialLink('https://www.facebook.com/152237441303167', 'fb://profile/152237441303167', 'intent://www.facebook.com/152237441303167#Intent;package=com.facebook.katana;scheme=https;end')">
                        <img src="/facebook-icon.svg" alt="Facebook profile" width="14px" height="14px"
                            style="display: inline-block; vertical-align: baseline; height: 14px;" />
                    </a>
                </li>
                <li>
                    <a target="_blank" aria-label="Visit our Instagram profile"
                        :href="generateSocialLink('https://www.instagram.com/audioaidynamics', 'instagram://user?username=audioaidynamics', 'intent://www.instagram.com/audioaidynamics#Intent;package=com.instagram.android;scheme=https;end')">
                        <img src="/instagram-icon.svg" alt="Instagram profile" width="14px" height="14px"
                            style="display: inline-block; vertical-align: baseline; height: 14px;" />
                    </a>
                </li>
                <li>
                    <a target="_blank" aria-label="Visit our X profile"
                        :href="generateSocialLink('https://twitter.com/audio_dynamics_', 'twitter://user?screen_name=audio_dynamics_', 'intent://twitter.com/audio_dynamics_#Intent;package=com.twitter.android;scheme=https;end')">
                        <img src="/twitter-x-icon.svg" alt="Twitter X profile" width="14px" height="14px"
                            style="display: inline-block; vertical-align: baseline; height: 14px;" />
                    </a>
                </li>
            </ul>
        </div>
        <div class="flex flex-col text-sm basis-full text-right italic max-sm:text-center">
            <span>Music Analyzer powered by Essentia.js and Essentia models, licensed by
                MTG.</span>
        </div>
    </div>
</template>