<script setup lang="ts">
interface IProps {
    color?: string
    width?: string
    height?: string
}
const props = defineProps<IProps>()
</script>
<template>
    <div role="status relative w-full"></div>
    <div class='flex space-x-2 justify-center items-center'>
        <span class='sr-only'>Loading...</span>
        <div :class="[props.color || 'bg-gray-500', props.height || 'h-2', props.width || 'w-2']" class='rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div :class="[props.color || 'bg-gray-500', props.height || 'h-2', props.width || 'w-2']" class='rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div :class="[props.color || 'bg-gray-500', props.height || 'h-2', props.width || 'w-2']" class='rounded-full animate-bounce'></div>
    </div>
</template>