import { getCookie } from '../helpers/cookieHelpers'
import baseEntityService from '../services/baseEntityService'

export default class LoginService extends baseEntityService<any> {
  private static instance: LoginService

  private constructor() {
    super('/api')
  }

  public static getInstance(): LoginService {
    if (!LoginService.instance)
      return new LoginService()

    return LoginService.instance
  }

  public refreshToken() {
    const cookie = getCookie('refresh_token')
    return this.getRequest(`/api/renew?refreshToken=${cookie}`)
  }

  public login = (dto: any) => {
    return this.postRequest(`api/login`, dto)
  }

  public logout = () => {
    return this.postRequest(`api/logout`, null)
  }

  public checkUsername = (username: string) => {
    return this.getRequest(`api/${username}/exists`)
  }
}
