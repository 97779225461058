<script setup lang="ts">

import BackToTop from './components/aaid/BackToTop.vue'
import { useRoute } from 'vue-router';
import Login from './components/Login.vue';
import { getCookie } from './shared/helpers/cookieHelpers.ts'
import Register from './components/Register.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import MeService from './shared/services/MeService';
import useUserStore from './stores/useUserStore';
import ToastMessage from './components/aaid/ToastMessage.vue';
import SideSocial from './components/aaid/SideSocial.vue';
import AppLayout from './layouts/AppLayout.vue';
import { bootstrap } from 'vue-gtag';
import VueCookieComply from '@ipaat/vue3-tailwind3-cookie-comply'
import { refreshAccessToken } from './shared/helpers/interceptors.ts';
import { generateSessionId, storeAnalytics } from './shared/helpers/AnalyticsHelper.ts';
const analyticsState = inject<{ analyticsCookie: boolean }>('analyticsState');
const route = useRoute()
const meService = MeService.getInstance()
const userStore = useUserStore()
const showSocial = ref(false)
const cookieInteraction = ref(false)
const reviewCookie = ref(false)
const analyticsCookie = ref(false)
const socialLogin = ref(false)
const cookieComplyTimeout: any = ref(null)
const preferences = [
  {
    title: 'Essential',
    description: 'These cookies are necessary for the website to function properly. They enable core functionalities such as page navigation, user authentication, and secure access. Without these cookies, certain parts of the website may not work as intended.',
    items: [
      {
        label: '',
        value: 'session',
        isRequired: true,
      },
    ],
  },
  {
    title: 'Analytics and performance',
    description: 'We use cookies to enhance your browsing experience, analyze website traffic, and understand how visitors interact with our site. This includes the use of Microsoft Clarity and Google Analytics for collecting anonymized data to measure website performance and usage patterns. These cookies help us improve our services and provide relevant content. By continuing to use our website, you consent to the use of these cookies in accordance with our Privacy Policy. https://audioaidynamics.com/privacy-policy',
    items: [
      {
        label: '',
        value: 'analytics',
        isEnabled: true,
      }
    ],
  },
  {
    title: 'Social login',
    description: 'These cookies facilitate user authentication through third-party platforms such as Google. They allow you to log in easily without creating a new account, ensuring a seamless and secure login experience.',
    items: [
      {
        label: '',
        value: 'social_login',
        isEnabled: true,
      }
    ],
  },
  {
    title: 'Review',
    description: 'These cookies are used to display and manage user reviews from platforms such as Sitejabber or Trustpilot. They enhance trust and transparency by showcasing genuine feedback, helping users make informed decisions.',
    items: [
      {
        label: '',
        value: 'review',
        isEnabled: true,
      }
    ],
  }
];
const checkForCookies = () => {
  let cookieComply = localStorage.getItem('cookie-comply')
  if (cookieComply) {
    cookieComply = JSON.parse(cookieComply)
    if (cookieComply && cookieComply.length) {
      reviewCookie.value = cookieComply.includes('review')
      analyticsCookie.value = cookieComply.includes('analytics')
      socialLogin.value = cookieComply.includes('social_login')
      if (reviewCookie.value) {
        addTrustPilotScript()
        addSiteJabberScript()
      }
      if (analyticsCookie.value) {
        addAnalyticsScript()
        addClarityScript()
        if (analyticsState) analyticsState.analyticsCookie = true
      } else {
        if (analyticsState) analyticsState.analyticsCookie = false
      }
      if (socialLogin.value) {
        addSocialLoginScript()
      }
    }
  }
  if (!cookieComply || cookieComply.length === 0) {
    cookieComplyTimeout.value = setTimeout(() => {
      cookieInteraction.value = true
    }, 3000)
  }
}
onMounted(async () => {
  window.addEventListener('beforeunload', handleBeforeUnload);
  checkSession()
  checkForCookies()
  if (getCookie('access_token')) {
    const user = await meService.get()
    if (user && user.data) {
      userStore.setUser(user.data)
      userStore.setAuthenticated(true)
    }
  } else if (getCookie('refresh_token')) {
    await refreshAccessToken()
    const user = await meService.get()
    if (user && user.data) {
      userStore.setUser(user.data)
      userStore.setAuthenticated(true)
    }
  }
  if (!(window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1'))) {
    enablePlugin()
  }
  setTimeout(() => {
    showSocial.value = true
  }, 30000)
  /* modelLoadStore.models.forEach((model: any) => {
    const worker = new FetchWorker();
    worker.postMessage({ path: model.path });

    worker.onmessage = (event: any) => {
      const { progress, data, error } = event.data;

      if (progress !== undefined) {
        modelLoadStore.setModelProgress(model.name, progress.toFixed(2));
      }

      if (data) {
        modelLoadStore.setModelLoaded(model.name, true);
        worker.terminate(); // Stop the worker when done
      }

      if (error) {
        modelLoadStore.setModelLoaded(model.name, false);
        modelLoadStore.setModelError(model.name, error);
        worker.terminate();
      }
    };
  }); */
})
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
})
const handleBeforeUnload = () => {
  storeAnalytics({
    exit_url: route.fullPath
  }, true)
}
const checkSession = () => {
  if (!localStorage.getItem('sessionId')) {
    localStorage.setItem('returnU', 'false')
    generateSessionId()
  } else {
    localStorage.setItem('returnU', 'true')
  }
  fetch('https://ipinfo.io/json?token=e195b1c5cfe7f0')
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem('anonymus_ip', data.ip)
    })
    .catch((error) => console.error('Error fetching IP info:', error));
}
const enablePlugin = () => {
  bootstrap().then(() => { })
}
const showbackground: any = ref(false);
const showbg = () => {
  showbackground.value = true
  setTimeout(() => {
    showbackground.value = false
  }, 100)

}
const onAccept = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
  checkForCookies()
}
const onDecline = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
}
const onSavePreferences = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
  checkForCookies()
}
const addTrustPilotScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
  script.async = true;
  document.head.appendChild(script);
}
const addSiteJabberScript = () => {
  const jabberScript = document.createElement('script');
  jabberScript.defer = true;
  jabberScript.async = true;
  jabberScript.textContent = `
  (function(d,s,id,c){var js,rC=[],uRC=[],r=0;Array.from(document.querySelectorAll('[class*="stjr-"]')).forEach(r=>{rC=[...rC,...Array.from(r.classList).filter((cl)=>{return /^stjr-/.test(cl);})]});uRC=[...new Set(rC)];t=d.getElementsByTagName(s)[0];js=d.createElement(s);js.id=id;js.src='https://www.sitejabber.com/js/v2/66ed3fe7ea152/widgets.js'+(uRC.length ? '?widget-classes=' + uRC.join("|") : '?widget-classes=stjr-base')+'';js.onload=js.onreadystatechange=function(){if(!r&&(!this.readyState||this.readyState[0]=='c')){r=1;c();}};t.parentNode.insertBefore(js,t);}(document,'script','sj-widget',function(){}));
`;
  document.body.appendChild(jabberScript);
}
const addAnalyticsScript = () => {
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5RFDK4D7" 
          height="0" 
          width="0" 
          style="display:none;visibility:hidden"></iframe>
`;
  document.body.insertBefore(noscript, document.body.firstChild);

  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.textContent = `
  (function (w, d, s, l, i) {
      w[l] = w[l] || []; 
      w[l].push({
        'gtm.start': new Date().getTime(), 
        event: 'gtm.js'
      }); 
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), 
          dl = l != 'dataLayer' ? '&l=' + l : ''; 
      j.async = true; 
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; 
      f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5RFDK4D7');
`;
  document.head.appendChild(script);

}
const addClarityScript = () => {
  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.textContent = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "pbic3zh6hh");`;
  document.head.appendChild(script);
}
const addSocialLoginScript = () => {
  const script = document.createElement('script');
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

}
</script>

<template>
  <div v-if="cookieInteraction"
    class="w-full fixed h-[100vh] top-0 bg-gray-700 opacity-80 z50 flex items-center justify-center">
    <span class="text-white text-lg font-bold w-[60%] max-sm:w-[320px] text-center">To continue using this website,
      please accept cookies. We use cookies to enhance your experience, analyze site usage and personalize content.
      You can accept all cookies, decline non-essential cookies or customize your preferences.</span>
  </div>
  <div class="w-full" :class="{ 'bg-blue-100': showbackground }">
    <component :is="route.meta.layout || AppLayout">
      <RouterView @on-bg-show="showbg" />
    </component>
    <BackToTop />
    <Login />
    <Register />
    <ForgotPassword />
    <ToastMessage />
    <SideSocial v-if="showSocial" />
    <VueCookieComply bannerTitle="Cookie Preferences" :preferences="preferences" @on-accept-all="onAccept"
      @on-decline-all="onDecline" @on-save-preferences="onSavePreferences">
    </VueCookieComply>
    <div v-if="reviewCookie" class="trustpilot-widget mb-5" data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66e62e2c4358e36e3659e17e"
      data-style-height="52px" data-style-width="100%">
      <a href="https://www.trustpilot.com/review/audioaidynamics.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  </div>
</template>

<style lang="scss">
.z55 {
  z-index: 9999;
  position: relative;
}

.z50,
aside {
  z-index: 999;
}

@media (max-width:480px) {
  aside>div>div.sm\:max-w-xl.mb-6.shadow-xl {
    top: 255px !important;
    position: relative;
  }
}
</style>
